import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col

} from 'reactstrap';

import FormBuilder from '../../components/forms/formBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Checkbox from '../../components/forms/fields/checkbox';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

class EditOurSolutionsPageForm extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            madiaLinks: [],
            loading: true,
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        if (this.props[0].match.params.id) {
            fetch(API_ENDPOINT + '/data/pages/our-solution/' + this.props[0].match.params.id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error,
                    })
                } else {
                    this.setState({
                        initialValues: result,
                    })
                }
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    stopAnimation = () => {
        this.player.current.play();
    }

    insertOrUpdate = (data) => {
        this.setState(
            {
                showPlayer: true,
            }, () => {
                this.player.current.play();
                fetch(API_ENDPOINT + '/data/pages/our-solution/' + data._id, {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            showPlayer: false
                        })
                    } else {
                        this.setState({
                            message: result.message,
                            showPlayer: false,
                            showPlayer2: true,
                        },
                            () => {
                                setTimeout(() => {
                                    this.setState({ showPlayer2: false });
                                }, 1000);
                            })
                        this.get();
                    }
                })
            }
        )
    }


    render() {

        return (
            <div className="dashboard" >
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: "20px" }}>
                                    <Checkbox label={'Section 1'} checked={this.state.initialValues && this.state.initialValues.section1} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section1 = this.state.initialValues.section1 ? !this.state.initialValues.section1 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 2'} checked={this.state.initialValues && this.state.initialValues.section2} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section2 = this.state.initialValues.section2 ? !this.state.initialValues.section2 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 3'} checked={this.state.initialValues && this.state.initialValues.section3} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section3 = this.state.initialValues.section3 ? !this.state.initialValues.section3 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 4'} checked={this.state.initialValues && this.state.initialValues.section4} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section4 = this.state.initialValues.section4 ? !this.state.initialValues.section4 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 5'} checked={this.state.initialValues && this.state.initialValues.section5} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section5 = this.state.initialValues.section5 ? !this.state.initialValues.section5 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 6'} checked={this.state.initialValues && this.state.initialValues.section6} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section6 = this.state.initialValues.section6 ? !this.state.initialValues.section6 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 7'} checked={this.state.initialValues && this.state.initialValues.section7} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section7 = this.state.initialValues.section7 ? !this.state.initialValues.section7 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 8'} checked={this.state.initialValues && this.state.initialValues.section8} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section8 = this.state.initialValues.section8 ? !this.state.initialValues.section8 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 9'} checked={this.state.initialValues && this.state.initialValues.section9} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section9 = this.state.initialValues.section9 ? !this.state.initialValues.section9 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 10'} checked={this.state.initialValues && this.state.initialValues.section10} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section10 = this.state.initialValues.section10 ? !this.state.initialValues.section10 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 11'} checked={this.state.initialValues && this.state.initialValues.section11} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section11 = this.state.initialValues.section11 ? !this.state.initialValues.section11 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 12'} checked={this.state.initialValues && this.state.initialValues.section12} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section12 = this.state.initialValues.section12 ? !this.state.initialValues.section12 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 13'} checked={this.state.initialValues && this.state.initialValues.section13} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section13 = this.state.initialValues.section13 ? !this.state.initialValues.section13 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 14'} checked={this.state.initialValues && this.state.initialValues.section14} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section14 = this.state.initialValues.section14 ? !this.state.initialValues.section14 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 15'} checked={this.state.initialValues && this.state.initialValues.section15} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section15 = this.state.initialValues.section15 ? !this.state.initialValues.section15 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 16'} checked={this.state.initialValues && this.state.initialValues.section16} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section16 = this.state.initialValues.section16 ? !this.state.initialValues.section16 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 17'} checked={this.state.initialValues && this.state.initialValues.section17} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section17 = this.state.initialValues.section17 ? !this.state.initialValues.section17 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                </div>
                                {
                                    this.state.initialValues ?

                                        <FormBuilder dateFormat={this.props.dateFormat}
                                            onSubmit={(data) => {
                                                this.insertOrUpdate(data)
                                            }
                                            }
                                            initialValues={this.state.initialValues}
                                            buttonText={
                                                this.state.showPlayer ? (
                                                    <Player
                                                        onEvent={(event) => {
                                                            if (event === "load") this.stopAnimation();
                                                        }}
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>

                                                ) : this.state.showPlayer2 ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player2} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>
                                                        {'Saved'.translate(this.props.lang)}
                                                    </div>
                                                ) : (
                                                    "Save".translate(this.props.lang)
                                                )
                                            }
                                            lang={this.props.lang}
                                            fields={[
                                                {
                                                    type: 'row',
                                                    children: [

                                                        {
                                                            type: 'col',
                                                            width: {
                                                                lg: 12,
                                                                sm: 12,
                                                                xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'row',
                                                                    children: [
                                                                        {
                                                                            type: 'col',
                                                                            width: {
                                                                                lg: 12,
                                                                                sm: 12,
                                                                                xs: 12
                                                                            },
                                                                            children: [
                                                                                {
                                                                                    type: 'h4',
                                                                                    text: 'Seo tag'
                                                                                },
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'seotag',
                                                                                    label: 'Title'.translate(this.props.lang),
                                                                                    multilang: true,
                                                                                    lang: this.props.lang,
                                                                                    // validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                },
                                                                                {
                                                                                    type: 'html',
                                                                                    name: 'seoDescription',
                                                                                    label: 'description'.translate(this.props.lang),
                                                                                    multilang: true,
                                                                                    lang: this.props.lang,
                                                                                    // validate: [required('description is required!'.translate(this.props.lang))]
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: {
                                                                                lg: 12,
                                                                                sm: 12,
                                                                                xs: 12
                                                                            },
                                                                            children: [
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'name',
                                                                                    label: 'Name'.translate(this.props.lang),
                                                                                    multilang: true,
                                                                                    lang: this.props.lang,
                                                                                    //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                },
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'alias',
                                                                                    label: 'Alias'.translate(this.props.lang),
                                                                                    multilang: true,
                                                                                    lang: this.props.lang,
                                                                                    //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                },
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'title',
                                                                                    label: 'Title'.translate(this.props.lang),
                                                                                    multilang: true,
                                                                                    lang: this.props.lang,
                                                                                    //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                },
                                                                                {
                                                                                    type: 'checkbox',
                                                                                    name: 'backgroundImagePositionLeft',
                                                                                    afterText: 'Background Image Position Left'.translate(this.props.lang),
                                                                                },
                                                                                {
                                                                                    type: 'checkbox',
                                                                                    name: 'backgroundImagePositionRight',
                                                                                    afterText: 'Background Image Position Right'.translate(this.props.lang),
                                                                                },
                                                                            ]
                                                                        }
                                                                    ]
                                                                },

                                                                this.state.initialValues && this.state.initialValues.section1 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [

                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 1'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [

                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[0].backgroundImage.en',
                                                                                        label: 'Background image EN'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[0].backgroundImage.se',
                                                                                        label: 'Background image SWE'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[0].topImg',
                                                                                        label: 'Top img technology & support'.translate(this.props.lang),

                                                                                    },

                                                                                ]
                                                                            },
                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section2 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 2'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[1].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[1].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'section[1].item[0].backgroundLightGrey',
                                                                                        afterText: 'Section 2 background light grey'.translate(this.props.lang),
                                                                                    },
                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'section[1].item[0].backgroundGrey',
                                                                                        afterText: 'Section 2 background grey'.translate(this.props.lang),
                                                                                    },



                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[1].item[0].image.en',
                                                                                        label: 'Image EN'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[1].item[0].image.se',
                                                                                        label: 'Image SWE'.translate(this.props.lang),

                                                                                    },

                                                                                ]
                                                                            },
                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section3 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 3'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[2].item[0].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[2].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[2].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'section[2].item[0].backgroundLightGrey',
                                                                                        afterText: 'Section 3 background light grey'.translate(this.props.lang),
                                                                                    },
                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'section[2].item[0].backgroundGrey',
                                                                                        afterText: 'Section 3 background grey'.translate(this.props.lang),
                                                                                    },


                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section4 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 4'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[3].item[0].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[3].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[3].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[3].item[1].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[3].item[1].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[3].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[3].item[2].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[3].item[2].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[3].item[2].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section5 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 5'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[4].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'section[4].item[0].isTitleLink',
                                                                                        afterText: 'Make this title a link'.translate(this.props.lang),
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[4].item[0].titleLinkText',
                                                                                        label: 'Title Link Text'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[4].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[4].item[1].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [

                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[4].item[1].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[4].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[4].item[2].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[4].item[2].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[4].item[2].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[4].item[3].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[4].item[3].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[4].item[3].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[4].item[4].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[4].item[4].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[4].item[4].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[4].item[5].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[4].item[5].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[4].item[5].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[4].item[6].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[4].item[6].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[4].item[6].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[4].item[7].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[4].item[7].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[4].item[7].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section6 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 6'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[5].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[5].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[5].item[1].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[5].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[5].item[2].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[5].item[2].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section7 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'Contact form text'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[6].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[6].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section8 ?

                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 8'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[7].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[7].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    // {
                                                                                    //     type: 'checkbox',
                                                                                    //     name: 'section[1].item[0].backgroundLightGrey',
                                                                                    //     afterText: 'Section 2 background light grey'.translate(this.props.lang),
                                                                                    // },
                                                                                    // {
                                                                                    //     type: 'checkbox',
                                                                                    //     name: 'section[1].item[0].backgroundGrey',
                                                                                    //     afterText: 'Section 2 background grey'.translate(this.props.lang),
                                                                                    // },



                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[7].item[0].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    // {
                                                                                    //     type: 'image',
                                                                                    //     name: 'section[1].item[0].image.se',
                                                                                    //     label: 'Image SWE'.translate(this.props.lang),

                                                                                    // },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section9 ?

                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 9'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[8].item[0].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    // {
                                                                                    //     type: 'image',
                                                                                    //     name: 'section[1].item[0].image.se',
                                                                                    //     label: 'Image SWE'.translate(this.props.lang),

                                                                                    // },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[8].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[8].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    // {
                                                                                    //     type: 'checkbox',
                                                                                    //     name: 'section[1].item[0].backgroundLightGrey',
                                                                                    //     afterText: 'Section 2 background light grey'.translate(this.props.lang),
                                                                                    // },
                                                                                    // {
                                                                                    //     type: 'checkbox',
                                                                                    //     name: 'section[1].item[0].backgroundGrey',
                                                                                    //     afterText: 'Section 2 background grey'.translate(this.props.lang),
                                                                                    // },



                                                                                ]
                                                                            },





                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section10 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 10 bellow "Adaptation to your local region"'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[9].item[0].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[9].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[9].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[9].item[0].textBtn',
                                                                                        label: 'Text button'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[9].item[0].linkBtn',
                                                                                        label: 'Link button'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    // {
                                                                                    //     type: 'checkbox',
                                                                                    //     name: 'section[2].item[0].backgroundLightGrey',
                                                                                    //     afterText: 'Section 3 background light grey'.translate(this.props.lang),
                                                                                    // },
                                                                                    // {
                                                                                    //     type: 'checkbox',
                                                                                    //     name: 'section[2].item[0].backgroundGrey',
                                                                                    //     afterText: 'Section 3 background grey'.translate(this.props.lang),
                                                                                    // },


                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section11 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 11'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[10].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[10].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },


                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[10].item[1].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[10].item[2].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,

                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[10].item[3].title',
                                                                                        label: 'Title 1'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[10].item[4].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[10].item[5].title',
                                                                                        label: 'Title 2'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[10].item[6].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[10].item[7].title',
                                                                                        label: 'Title 3'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[10].item[8].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[10].item[9].title',
                                                                                        label: 'Title 4'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[10].item[10].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section12 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 12'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[11].item[0].image.se',
                                                                                        label: 'Image SE'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[11].item[0].image.en',
                                                                                        label: 'Image EN'.translate(this.props.lang),

                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[11].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[11].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },


                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section13 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 13'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[12].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,

                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[12].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[12].item[1].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[12].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[12].item[2].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[12].item[2].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },


                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[12].item[3].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[12].item[3].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },


                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[12].item[4].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[12].item[4].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },


                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[12].item[5].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[12].item[5].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },


                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[12].item[6].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[12].item[6].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },


                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section14 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 14'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[13].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[13].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },


                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[13].item[1].title',
                                                                                        label: 'Title 1'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[13].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[13].item[2].title',
                                                                                        label: 'Title 2'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[13].item[2].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[13].item[3].title',
                                                                                        label: 'Title 3'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[13].item[3].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[13].item[4].title',
                                                                                        label: 'Title 4'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[13].item[4].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[13].item[5].title',
                                                                                        label: 'Title 5'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[13].item[5].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[13].item[6].title',
                                                                                        label: 'Title 6'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[13].item[6].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section15 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 15'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[14].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    // {
                                                                                    //     type: 'html',
                                                                                    //     name: 'section[13].item[0].shortDescription',
                                                                                    //     label: 'Short Description'.translate(this.props.lang),
                                                                                    //     height: 250,
                                                                                    //     className: 'estate-textarea-size',
                                                                                    //     multilang: true,
                                                                                    //     lang: this.props.lang,
                                                                                    // },


                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[14].item[1].image.se',
                                                                                        label: 'Image SE'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[14].item[1].image.en',
                                                                                        label: 'Image EN'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[14].item[1].alt',
                                                                                        label: 'Alt image'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[14].item[1].title',
                                                                                        label: 'Title 1'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[14].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[14].item[2].image.se',
                                                                                        label: 'Image SE'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[14].item[2].image.en',
                                                                                        label: 'Image EN'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[14].item[2].alt',
                                                                                        label: 'Alt image'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[14].item[2].title',
                                                                                        label: 'Title 2'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[14].item[2].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[14].item[3].image.se',
                                                                                        label: 'Image SE'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[14].item[3].image.en',
                                                                                        label: 'Image EN'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[14].item[3].alt',
                                                                                        label: 'Alt image'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[14].item[3].title',
                                                                                        label: 'Title 3'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[14].item[3].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            // {
                                                                            //     type: 'col',
                                                                            //     width: {
                                                                            //         lg: 4,
                                                                            //         sm: 12,
                                                                            //         xs: 12
                                                                            //     },
                                                                            //     children: [
                                                                            //         {
                                                                            //             type: 'text',
                                                                            //             name: 'section[13].item[4].title',
                                                                            //             label: 'Title 4'.translate(this.props.lang),
                                                                            //             multilang: true,
                                                                            //             lang: this.props.lang,
                                                                            //             //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                            //         },
                                                                            //         {
                                                                            //             type: 'html',
                                                                            //             name: 'section[13].item[4].shortDescription',
                                                                            //             label: 'Short Description'.translate(this.props.lang),
                                                                            //             height: 250,
                                                                            //             className: 'estate-textarea-size',
                                                                            //             multilang: true,
                                                                            //             lang: this.props.lang,
                                                                            //         },

                                                                            //     ]
                                                                            // },
                                                                            // {
                                                                            //     type: 'col',
                                                                            //     width: {
                                                                            //         lg: 4,
                                                                            //         sm: 12,
                                                                            //         xs: 12
                                                                            //     },
                                                                            //     children: [
                                                                            //         {
                                                                            //             type: 'text',
                                                                            //             name: 'section[13].item[5].title',
                                                                            //             label: 'Title 5'.translate(this.props.lang),
                                                                            //             multilang: true,
                                                                            //             lang: this.props.lang,
                                                                            //             //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                            //         },
                                                                            //         {
                                                                            //             type: 'html',
                                                                            //             name: 'section[13].item[5].shortDescription',
                                                                            //             label: 'Short Description'.translate(this.props.lang),
                                                                            //             height: 250,
                                                                            //             className: 'estate-textarea-size',
                                                                            //             multilang: true,
                                                                            //             lang: this.props.lang,
                                                                            //         },

                                                                            //     ]
                                                                            // },
                                                                            // {
                                                                            //     type: 'col',
                                                                            //     width: {
                                                                            //         lg: 4,
                                                                            //         sm: 12,
                                                                            //         xs: 12
                                                                            //     },
                                                                            //     children: [
                                                                            //         {
                                                                            //             type: 'text',
                                                                            //             name: 'section[13].item[6].title',
                                                                            //             label: 'Title 6'.translate(this.props.lang),
                                                                            //             multilang: true,
                                                                            //             lang: this.props.lang,
                                                                            //             //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                            //         },
                                                                            //         {
                                                                            //             type: 'html',
                                                                            //             name: 'section[13].item[6].shortDescription',
                                                                            //             label: 'Short Description'.translate(this.props.lang),
                                                                            //             height: 250,
                                                                            //             className: 'estate-textarea-size',
                                                                            //             multilang: true,
                                                                            //             lang: this.props.lang,
                                                                            //         },

                                                                            //     ]
                                                                            // },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section16 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 16'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[15].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[15].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },


                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[15].item[1].image.se',
                                                                                        label: 'Image SE'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[15].item[1].image.en',
                                                                                        label: 'Image EN'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[15].item[1].alt',
                                                                                        label: 'Alt image'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },

                                                                                ]
                                                                            },
                                                                            // {
                                                                            //     type: 'col',
                                                                            //     width: {
                                                                            //         lg: 12,
                                                                            //         sm: 12,
                                                                            //         xs: 12
                                                                            //     },
                                                                            //     children: [
                                                                            //         {
                                                                            //             type: 'text',
                                                                            //             name: 'section[10].item[2].title',
                                                                            //             label: 'Title'.translate(this.props.lang),
                                                                            //             multilang: true,
                                                                            //             lang: this.props.lang,

                                                                            //         },

                                                                            //     ]
                                                                            // },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[15].item[3].title',
                                                                                        label: 'Title 1'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[15].item[4].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[15].item[5].title',
                                                                                        label: 'Title 2'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[15].item[6].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[15].item[7].title',
                                                                                        label: 'Title 3'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[15].item[8].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[15].item[9].title',
                                                                                        label: 'Title 4'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[15].item[10].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                // this.state.initialValues && this.state.initialValues.section16 ?
                                                                //     {
                                                                //         type: 'row',
                                                                //         children: [
                                                                //             {
                                                                //                 type: 'col',
                                                                //                 width: {
                                                                //                     lg: 12,
                                                                //                     sm: 12,
                                                                //                     xs: 12
                                                                //                 },
                                                                //                 children: [
                                                                //                     {
                                                                //                         type: 'h4',
                                                                //                         text: 'section 16'
                                                                //                     },

                                                                //                 ]
                                                                //             },
                                                                //             {
                                                                //                 type: 'col',
                                                                //                 width: {
                                                                //                     lg: 6,
                                                                //                     sm: 12,
                                                                //                     xs: 12
                                                                //                 },
                                                                //                 children: [
                                                                //                     {
                                                                //                         type: 'text',
                                                                //                         name: 'section[15].item[0].title',
                                                                //                         label: 'Title'.translate(this.props.lang),
                                                                //                         multilang: true,
                                                                //                         lang: this.props.lang,
                                                                //                         //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                //                     },
                                                                //                     {
                                                                //                         type: 'html',
                                                                //                         name: 'section[15].item[0].shortDescription',
                                                                //                         label: 'Short Description'.translate(this.props.lang),
                                                                //                         height: 250,
                                                                //                         className: 'estate-textarea-size',
                                                                //                         multilang: true,
                                                                //                         lang: this.props.lang,
                                                                //                     },


                                                                //                 ]
                                                                //             },
                                                                //             {
                                                                //                 type: 'col',
                                                                //                 width: {
                                                                //                     lg: 6,
                                                                //                     sm: 12,
                                                                //                     xs: 12
                                                                //                 },
                                                                //                 children: [
                                                                //                     {
                                                                //                         type: 'image',
                                                                //                         name: 'section[15].item[1].image',
                                                                //                         label: 'Image'.translate(this.props.lang),

                                                                //                     },

                                                                //                 ]
                                                                //             },
                                                                //             // {
                                                                //             //     type: 'col',
                                                                //             //     width: {
                                                                //             //         lg: 12,
                                                                //             //         sm: 12,
                                                                //             //         xs: 12
                                                                //             //     },
                                                                //             //     children: [
                                                                //             //         {
                                                                //             //             type: 'text',
                                                                //             //             name: 'section[10].item[2].title',
                                                                //             //             label: 'Title'.translate(this.props.lang),
                                                                //             //             multilang: true,
                                                                //             //             lang: this.props.lang,

                                                                //             //         },

                                                                //             //     ]
                                                                //             // },
                                                                //             {
                                                                //                 type: 'col',
                                                                //                 width: {
                                                                //                     lg: 4,
                                                                //                     sm: 12,
                                                                //                     xs: 12
                                                                //                 },
                                                                //                 children: [
                                                                //                     {
                                                                //                         type: 'text',
                                                                //                         name: 'section[15].item[3].title',
                                                                //                         label: 'Title 1'.translate(this.props.lang),
                                                                //                         multilang: true,
                                                                //                         lang: this.props.lang,
                                                                //                         //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                //                     },
                                                                //                     {
                                                                //                         type: 'html',
                                                                //                         name: 'section[15].item[4].shortDescription',
                                                                //                         label: 'Short Description'.translate(this.props.lang),
                                                                //                         height: 250,
                                                                //                         className: 'estate-textarea-size',
                                                                //                         multilang: true,
                                                                //                         lang: this.props.lang,
                                                                //                     },

                                                                //                 ]
                                                                //             },
                                                                //             {
                                                                //                 type: 'col',
                                                                //                 width: {
                                                                //                     lg: 4,
                                                                //                     sm: 12,
                                                                //                     xs: 12
                                                                //                 },
                                                                //                 children: [
                                                                //                     {
                                                                //                         type: 'text',
                                                                //                         name: 'section[15].item[5].title',
                                                                //                         label: 'Title 2'.translate(this.props.lang),
                                                                //                         multilang: true,
                                                                //                         lang: this.props.lang,
                                                                //                         //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                //                     },
                                                                //                     {
                                                                //                         type: 'html',
                                                                //                         name: 'section[15].item[6].shortDescription',
                                                                //                         label: 'Short Description'.translate(this.props.lang),
                                                                //                         height: 250,
                                                                //                         className: 'estate-textarea-size',
                                                                //                         multilang: true,
                                                                //                         lang: this.props.lang,
                                                                //                     },

                                                                //                 ]
                                                                //             },
                                                                //             {
                                                                //                 type: 'col',
                                                                //                 width: {
                                                                //                     lg: 4,
                                                                //                     sm: 12,
                                                                //                     xs: 12
                                                                //                 },
                                                                //                 children: [
                                                                //                     {
                                                                //                         type: 'text',
                                                                //                         name: 'section[15].item[7].title',
                                                                //                         label: 'Title 3'.translate(this.props.lang),
                                                                //                         multilang: true,
                                                                //                         lang: this.props.lang,
                                                                //                         //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                //                     },
                                                                //                     {
                                                                //                         type: 'html',
                                                                //                         name: 'section[15].item[8].shortDescription',
                                                                //                         label: 'Short Description'.translate(this.props.lang),
                                                                //                         height: 250,
                                                                //                         className: 'estate-textarea-size',
                                                                //                         multilang: true,
                                                                //                         lang: this.props.lang,
                                                                //                     },

                                                                //                 ]
                                                                //             },
                                                                //             {
                                                                //                 type: 'col',
                                                                //                 width: {
                                                                //                     lg: 4,
                                                                //                     sm: 12,
                                                                //                     xs: 12
                                                                //                 },
                                                                //                 children: [
                                                                //                     {
                                                                //                         type: 'text',
                                                                //                         name: 'section[15].item[9].title',
                                                                //                         label: 'Title 4'.translate(this.props.lang),
                                                                //                         multilang: true,
                                                                //                         lang: this.props.lang,
                                                                //                         //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                //                     },
                                                                //                     {
                                                                //                         type: 'html',
                                                                //                         name: 'section[15].item[10].shortDescription',
                                                                //                         label: 'Short Description'.translate(this.props.lang),
                                                                //                         height: 250,
                                                                //                         className: 'estate-textarea-size',
                                                                //                         multilang: true,
                                                                //                         lang: this.props.lang,
                                                                //                     },

                                                                //                 ]
                                                                //             },

                                                                //         ]
                                                                //     } : null,
                                                                this.state.initialValues && this.state.initialValues.section17 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 17'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [

                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[16].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[16].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[16].item[1].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[16].item[1].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[16].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[16].item[2].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[16].item[2].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[16].item[2].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[16].item[3].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[16].item[3].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[16].item[3].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 4,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[16].item[4].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[16].item[4].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[16].item[4].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,




                                                            ]
                                                        },
                                                    ]
                                                }
                                            ]}
                                        ></FormBuilder> : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(EditOurSolutionsPageForm);